import { Content, Header, Page } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useHomePageStyles } from '../../style/custom';
import { HomePageSearchBar } from '@backstage/plugin-search';
import {
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageRecentlyVisited,
  HomePageTopVisited,
} from '@backstage/plugin-home';
import { GrafanaIcon } from '../../components/icons/toolkit/GrafanaIcon';
import { LookerIcon } from '../../components/icons/toolkit/LookerIcon'

const toolkitData = [
  {
    url: 'https://grafana.o11y.web3factory.consensys.net/?orgId=23',
    label: 'Grafana',
    icon: <GrafanaIcon width={24} />,
  },
  {
    url: 'https://consensysus.cloud.looker.com/login',
    label: 'Looker',
    icon: <LookerIcon width={60} />
  }
];

export const HomePage = () => {
  const classes = useHomePageStyles();

  return (
    <Page themeId="home">
      <Header title="Home" />
      <Content>
        <Grid container direction="row">
          <Grid item xs={12}>
            <HomePageSearchBar
              classes={{ root: classes.searchBar }}
              InputProps={{
                classes: { notchedOutline: classes.searchBarOutline },
              }}
              placeholder="Search"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageTopVisited
              kind="top"
              numVisitsOpen={3}
              numVisitsTotal={6}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageRecentlyVisited
              kind="recent"
              numVisitsOpen={3}
              numVisitsTotal={6}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageStarredEntities />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageToolkit tools={toolkitData} />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
